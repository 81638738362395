// src/assets/skillsData.js

// Import SVG icons
import cpp from './svgs/cpp.svg';
import python from './svgs/python.svg';
import java from './svgs/java.svg';
import css from './svgs/css.svg';
import html from './svgs/html.svg';
import javascript from './svgs/javascript.svg';
import dart from './svgs/dart.svg';
import react from './svgs/react.svg';
import vue from './svgs/vue.svg';
import django from './svgs/django.svg';
import qt from './svgs/qt.svg';
import spring from './svgs/spring.svg';
import flutter from './svgs/flutter.svg';
import mysql from './svgs/mysql.svg';
import postgresql from './svgs/postgresql.svg';
import azure from './svgs/azure.svg';
import git from './svgs/git.svg';
import docker from './svgs/docker.svg';

const skillsData = {
  Languages: [
    {
      name: 'C++',
      icon: cpp,
    },
    {
      name: 'Python',
      icon: python,
    },
    {
      name: 'Java',
      icon: java,
    },
    {
      name: 'CSS',
      icon: css,
    },
    {
      name: 'HTML',
      icon: html,
    },
    {
      name: 'JavaScript',
      icon: javascript,
    },
    {
      name: 'Dart',
      icon: dart,
    }
  ],
  Frameworks: [
    {
      name: 'React.js',
      icon: react,
    },
    {
      name: 'VUE.js',
      icon: vue,
    },
    {
      name: 'Django',
      icon: django,
    },
    {
      name: 'Qt',
      icon: qt,
    },
    {
      name: 'Spring Boot',
      icon: spring,
    },
    {
      name: 'Flutter',
      icon: flutter,
    }
  ],
  DBs: [
    {
      name: 'MySQL',
      icon: mysql,
    },
    {
      name: 'PostgreSQL',
      icon: postgresql,
    }
  ],
  Other: [
    {
      name: 'Azure',
      icon: azure,
    },
    {
      name: 'Git',
      icon: git,
    },
    {
      name: 'Docker',
      icon: docker,
    }
  ],
};

export default skillsData;
